export const INVEST_OR_FUNDRAISING_SECTION_ID = 'learn-more'
export const WANT_TO_INVEST_SECTION_ID = 'want-to-invest'
export const WANT_FUNDRAISING_SECTION_ID = 'want-fundraising'
export const COMPANY_SIGNUP_LINK =
  'https://app.dev.ubitscapital.com/company/sign-up'
export const INVESTOR_SIGNUP_LINK =
  'https://app.dev.ubitscapital.com/customer/sign-up'
export const INVESTOR_LOGIN_LINK = 'https://app.dev.ubitscapital.com/login'
export const FUNDRAISE_HASHCODE = 'hxCeDdiNXQVHYu'
export const INVEST_HASHCODE = 'MbpvcncTYveXFj'
export const UBITS_WHATSAPP = 'https://wa.me/+5519999534910'
export const PUBLIC_WEB_APP_DOMAIN = 'https://app.dev.ubitscapital.com'
export const PUBLIC_OFFERS_API = 'https://offers.dev.ubitscapital.com/offers'
